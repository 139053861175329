import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'image-lightbox',
  templateUrl: './image-lightbox.component.html',
  styleUrls: ['./image-lightbox.component.scss']
})
export class ImageLightboxComponent implements OnInit {

  @Input() image: string;
  public zoom = false;

  constructor() { }

  ngOnInit(): void {
  }

}
