<br>
<div class="container" style="margin-top:30px">
    <div class="row">
      <div class="col-sm-4">
        <h1>Kontakt</h1>
      </div>
      <div class="col-sm-8 text-justify">        
            <p>Schicken Sie Ihre Nachricht bitte an:</p>
            <a href="mailto:info@elenakolesnitschenko.de">info@elenakolesnitschenko.de</a>
        </div>        
    </div>
  </div>