import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { News } from '../../app.interfaces';
import * as moment from 'moment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  safeSrc: SafeResourceUrl;
  news: any[];
  
  constructor(
    private API: AppService,
    private sanitizer: DomSanitizer
    
  ) {     }

  ngOnInit() {
    this.API.getJSON("./assets/news.json").subscribe(data => {
      this.news = [];
      data.forEach((news: News) => {
        if (moment(news.showFrom).diff(moment(), 'days') <= 0 && moment(news.showTo).diff(moment(), 'days') >= 0) {
          news.safeurl = this.sanitizer.bypassSecurityTrustResourceUrl(news.youtubeurl);
          this.news.push(news);
        }
      });    

    });

  }

  

}
