<navbar></navbar>

 <router-outlet></router-outlet>

  <footer class="footer fixed-bottom">
    <div class="container text-center" padding-top="10px">
      <span class="text-muted">
        <p>
        <br>
       <!--  <a target='_blank' rel="noopener noreferrer" href="https://www.instagram.com/kolesnitschenkoelena/">
          <span style="font-size: 1.5rem;">
              <span style="color: #6C757D;">
                 <i class="fab fa-instagram"></i>
              </span>
          </span>
       </a><a target='_blank' rel="noopener noreferrer" style="color: #6C757D;" href="https://www.instagram.com/kolesnitschenkoelena/"> Instagram </a>
        -->
       <a target='_blank' rel="noopener noreferrer" href="https://www.facebook.com/elena.kolesnitschenko">
          <span style="font-size: 1.5rem;">
              <span style="color: #6C757D;">
                <i class="fab fa-facebook"></i>
              </span>
          </span>
        </a><a target='_blank' rel="noopener noreferrer" style="color: #6C757D;" href="https://www.facebook.com/elena.kolesnitschenko"> Facebook </a>
        <br>
        <a style="color: #6C757D;" [routerLink]="['impressum']"> Impressum </a>
        &copy; Elena Kolesnitschenko 2023
        </p>
      </span>
    </div>
  </footer>