<!-- Generator: Adobe Illustrator 23.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<!-- <svg version="1.2" baseProfile="tiny" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
	 x="0px" y="0px" viewBox="0 0 1500 350" xml:space="preserve">
<path fill="#AC9C2F" d="M314.5,254.6c0,0-5.3,1.1-9.1,0.8c-4.9-0.4-10.1-2.2-12.9-4c-5.3-3.5-10-8.9-12.6-12.1
	c-5-6.1-9.1-13.9-10.8-16.5c-0.9-1.4-12.3-22.4-13.7-25.2c-1.1-2.2-4.2-8.4-8.3-14.3c-4.3-6.2-9.7-12.1-12.1-14.9
	c-4.2-5.1-8.5-9.3-9.5-10.2c-4.3-4.2-10.3-9.9-11.1-10.5c-3.8-2.6-8-5.4-8-5.4l-14.8,2.7c0,0,0-1.9,0-2.7c0.1-8.1,0.3-16.2,0.4-24.3
	c0.1-6.7,0.2-13.4,0.4-20c0.2-6.4,0.6-12.7,0.8-19.1c0.2-5.7,0.4-10.6,1-16.3c0.6-5.5,1.5-11.8,2.7-17.3c1.1-4.5,1.2-4.4,2.4-8.7
	c-2.4-0.5-22.2-5.3-41.8-9.5c-6.7-1.4-21.1-3.7-32.8-3c-8.5,0.5-15.6,3.6-17.7,4.6c-6.1,2.8-11,7.1-15.8,11.6
	c-6.5,6.2-11.5,13.6-13.2,22.5c-1.4,6.9-1.9,14.1-0.8,21.3c1.3,9.1,5.2,17.5,9.3,22.1c2.1,2.3,11.5,10.1,24.8,13.1
	c13.4,3,22.4,1.9,30.5,0c6.2-2.1,12.9-6.9,16-9.8c0.6-0.5,6.8-5.9,10.6-13.3c3-5.8,4-13.2,4.4-17.9c0.8-8.3-7.2-21.1-9.2-23.7
	c-5.7-7-15.1-12.1-16.1-13c0-0.1,6.1,1.5,12.1,4.7c2.9,1.5,9,5.7,13.2,10.5c1.8,2.1,6.2,8.6,7.7,16.5c0.7,3.9,0.7,13-1.7,20.9
	c-2.5,8.2-7.5,15.1-10.6,18.1c-5.9,5.9-16,13.3-28.2,16.1c-11.4,2.6-27.4,1.6-35.5-0.3c-7.4-1.7-20.7-7.6-29.4-16.3
	c-6.6-6.6-10.1-15.3-11.1-18.7c-1.5-5.7-2.1-11.2-1.9-22.5c0.2-11.9,5-21.4,15.1-33.9c5.8-7.2,20.9-21.1,44.6-23.2
	c15.6-1.3,26.3,1.4,34.2,3.5C167,19.8,192,26,197.8,27.7c1.5,0.2,3.7,0.9,4.8,0.9c0.6-0.1,1.1-1.7,1.4-2.3c1.6-3,2.9-6.2,4.6-9.1
	c3.2-5.2,7-10,12.6-12.8c3.3-1.7,6.9-1.9,10.1,0.5c2.8,2.1,2.9,5.2,2.4,8.1c-0.5,2.7-2.1,4.9-4.1,7c-1.6,1.7-11.5,7.3-16,13.1
	c-1.2,1.6-3.7,8.5-5,15.9c-0.2,1.4-1.4,10.1-1.6,14.5c-0.9,18.1-0.5,50.9-0.8,67.5c0,0,13.6,6.9,19.4,10.2c4.4,2.5,8.9,4.9,13.1,7.8
	c1.3,0.9,6.9,4.5,11.5,9c2.8,2.7,10.2,11.3,15.5,20.1c2.4,4,11.3,26.3,13,31.3c0.7,2.1,3.5,9.8,6.6,17.1c1.4,3.3,3.4,9.9,6.3,14.7
	c2.8,4.6,6.5,7.4,8.2,8.4c3.2,1.9,6.7,2.5,10.3,2.6c3.6,0,6.8-1.1,8.8-4.6c1.3-2.2,3.3-5.2,3.2-8.7c-0.1-3.6-2.3-7.7-2.3-7.7
	s3.8,3.2,4.9,8.5c0.4,1.8,0.2,6.8-1.5,11.3c-0.9,2.4-3.6,7.4-7.4,11.3c-1.9,1.9-6.2,5.3-12,7.5c-3,1.1-14.9,2.6-24.6,1.1
	c-7-1.1-12.5-5-14.2-5.9c-5.8-3.3-10.4-8-13.2-13.9c-2.3-4.8-4.2-9.9-4.5-15.4c-0.2-3-0.6-6.1-0.4-9.1c0.2-2.4,0.5-8.6,2.4-14
	c2.1-5.9,5.6-10.9,5.6-10.9l7.5,13.8c0,0-3.7,9.8-3.8,14.5c-0.1,5.4,1.2,10.9,2.1,13.6c1.7,4.5,4.9,8.7,6.5,10.4
	c3.5,3.8,8.6,7.2,11.9,8.3c6.7,2.4,15.2,2.4,17.6,2c4.8-1,9.2-3.1,11-4.2C311.8,257.8,314.5,254.6,314.5,254.6z M225,16.2
	c2-1.6,3.4-4,2.5-5.3c-0.4-0.7-3.7-1.2-6.3,1.7c-3.6,3.9-6.7,11.6-6.6,11.6"/>
<path fill="#AC9C2F" d="M124.2,260.8c7-2.6,13.6-8.7,17.8-12.9c8.9-8.7,12.3-20.5,13-24.3c1.5-7.7,0.2-15.6-0.2-18.1
	c-0.2-1.1-1.2-8.3-4-14.7c-3.2-7-8.3-13.2-10.1-14.9c-3-2.9-10.4-8.9-19.6-12.6c-9.8-3.9-21.6-5.3-28.6-5.4
	c-6.4-0.1-19.2,2.1-28.9,6.5c-4.3,1.9-14.7,8.1-21.8,15.7c-3.3,3.5-8.1,10.4-11.3,17.7c-1.7,3.9-5.1,12.9-6.2,21.6
	c-0.5,4-1.4,16,0.7,27.1c1.1,5.6,4.1,17.1,9.7,26.9c2.8,5,11.8,16.4,23.1,25c6,4.6,17.6,11.1,30.1,14.6c6.3,1.8,18.8,3.1,30,2
	c5.2-0.5,15.1-2.4,23.9-6.7c4.6-2.3,14.7-9.5,22.3-17.4c6.8-7,11.6-14.8,12-15.7l4.3,8.9c-1.1,1.6-4.9,8.1-11.9,14.7
	c-5,4.7-13.4,12.9-23.1,18c-4.4,2.3-12.9,6.4-22.4,8c-5.5,0.9-19.7,2.8-32.8,1.2c-7.5-0.9-20.5-3.9-31.6-8.7
	c-5.5-2.4-16.2-8.1-24.8-15.3c-4.5-3.7-11.3-11.6-16.2-19c-2.2-3.4-7.3-12.8-10.2-21.6c-1.2-3.7-3.7-12.5-4.3-21.4
	c-0.3-4.2-0.2-14.4,1.7-23.4c1-4.9,4.6-16.2,10.4-25.6c2.9-4.8,8.9-12.7,15.5-18.8c3-2.7,10.7-8.7,18.7-12.9
	c3.1-1.6,11.4-5.4,20.6-7.4c9.4-2.1,19.8-2.5,26.5-2.1c8.5,0.5,13.4,1.5,18.5,2.8c8.7,2.4,14.9,4.9,21.8,9.5
	c2.6,1.7,5.4,3.3,7.6,5.4c2.8,2.8,5.5,5.2,8,8.4c1.8,2.2,3.2,5.4,4.6,7.8c1.4,2.4,2.6,5,3.4,7.7c1.1,3.1,2,6.3,2.5,9.6
	c0.9,6.1,0.8,10,0,18.3c-0.3,3.5-0.9,7.4-2.1,10.7c-1.7,4.3-3,6.9-4.9,10.2c-2.5,4.2-5.4,8.1-8.8,11.7c-1.5,1.6-3.3,2.8-5,4.2
	c-0.7,0.6-6.2,5.2-13.2,7.4c-7.4,2.4-16.3,2.5-19.1,1.8c-7.4-1.7-11-5.9-11.7-6.8c0,0,4.5,2.6,10.2,3.6
	C113.4,263.2,120,262.4,124.2,260.8z"/>
<path fill="#AC9C2F" d="M262.4,81.4c-1,2.7-1.6,3.8-2.3,6.5c-1.1,3.9-1.6,8-2.9,11.8c-1.4,4.2-3.2,8.2-5.1,12.2
	c-0.6,1.3-2.8,5.6-6.1,10c-3.4,4.7-7.9,9.5-10.2,11.8c-4.1,4-8.2,6.6-8.2,6.6l-8.9-5.2c0,0,4.7-7.2,9.5-14.3l8.9-13.1
	c2.4-5.6,4.1-11,4.6-12.5c2.1-7,4.7-14.8,6.2-18.3c1.5-3.5,3.9-10.6,7.8-16.3c3.8-5.6,9-9.7,10.8-11.1c4.2-3.3,10.1-5.5,12.5-6.3
	c4.5-1.5,9.1-1.5,10.8-1.2c4.8,0.8,9,3.5,9.3,3.9c-4.6-1.1-13.4-1.1-19.6,2.4c-6.5,3.7-10.4,11-11.3,13c-2,4.6-2.4,10-2.2,12.2
	c0.5,6.5,2.8,14.7,5.3,18.6c5,7.9,12.8,13.6,16.6,14.7c7.9,2.4,17,2.5,21.1,1.9c7.3-1.1,14.5-4.9,17.8-7.7c2.8-2.4,9-9.1,11.4-16.2
	c2.5-7.3,1.2-15.1,0.8-18.7c-0.8-7.6-3.7-15.6-5.8-19c-3.3-5.4-7.3-10.1-11.9-14.3c-6.7-6-14.4-9.4-23.2-11.1
	c-7.4-1.4-14.5-0.5-21.7,0.9c-3.4,0.7-12,2.8-18.5,6.5c-3.5,2-9.4,6.7-13.9,11.9c-2.4,2.8-7,8.6-10.3,15.3c-3.2,6.6-5.1,14-5.6,17.7
	c-0.5,3.4-1.1,12,1,18.9c2.5,8.3,6.4,12.9,6.4,12.9l-7.6,11.6c0,0-4.6-4.1-8.1-11.6c-3.8-8-4.4-17.5-4.4-22.8
	c-0.1-11.3,4.1-23,6.3-27.7c2.1-4.5,6.7-12.6,12.4-18.8c6-6.5,13.3-11.1,15.2-12.3c4.3-2.7,13.6-7.3,23.4-9.4
	c10.3-2.1,21.3-1.7,26.4-1.1c4.4,0.5,14.3,2.3,22.6,6.5c8.8,4.4,16,11.2,17.9,13.3c2.8,3,8.1,8.8,11.4,15.7c1.5,3.1,4.3,9.5,5,16.2
	c0.3,2.9,0.2,13-3.3,21.6c-1.8,4.4-8.2,15.2-18.1,21.8c-4.7,3.2-17.3,7.6-28.6,7.5c-6.1,0-16.8-2.1-24.8-5.9
	c-6.9-3.3-10.5-7.5-13.1-11.4C263.6,91.7,262.4,81.4,262.4,81.4z"/>
<path fill="#AC9C2F" d="M276.1,195.8l-4.5-10.4c0,0,13.9-7,23.9-10.3c4-1.3,13.9-3.5,23.4-3.5c9.6,0.1,19,2.4,23.2,4
	c2.6,1,15.7,5.9,24.3,14.7c10.3,10.5,16.3,25.4,17.8,30.7c3.3,11.1,4.1,24,3.8,29.6c-0.7,11.7-4.7,23.8-8.2,29.8
	c-8.3,14.2-22.9,27.7-30.2,32.7c-13.6,9.3-31.2,15.7-34.4,16.6c-8.6,2.5-18.4,4.2-22.7,4.6c-8.6,0.8-18.7,0.3-23-0.1
	c-9.6-1-20.1-3.5-24.7-4.8c-9.4-2.6-19.1-6.6-22.4-8c-6.5-2.9-12.2-6.5-14.9-8.4c-6.5-4.6-12.2-10.8-14.9-13.8
	c-4.9-5.7-8.6-12.7-10.2-15.2l-5.1-10.7c-1.3-2.9-3.5-10.6-5-19.2c-1.6-9.3-2.3-19.5-2.3-24.6c-0.1-9.9,1.4-19.5,2.5-23.9
	c2.4-9.2,7.6-17.4,9.5-20.7c4.1-7.3,10.1-13.8,10.1-13.8l0.4,18.8c0,0-3.8,4.3-5.8,9.5c-0.9,2.3-2.6,9.9-3.1,18.6
	c-0.3,5.1-0.5,14.5,0,23.4c0.4,8.2,2.1,19.4,2.1,19.4l3.6,12.5c0.7,2,3,8,6.4,13.4c1.6,2.6,6.3,9,11.7,14.1c5,4.8,10.6,8.3,11.9,9.3
	c1.2,0.9,5.7,3.7,11.1,6.5c2.6,1.4,9.1,4.6,15.9,7.1c3,1.1,10.4,3.5,17.4,4.8c4,0.7,15.5,2.1,27.1,0.7c5.5-0.7,19.2-4,31.2-9.4
	c6.1-2.7,18.1-9.3,27.3-17.8c9.2-8.6,15.5-19.2,18.3-25.6c1.3-3,6-15.7,7-28.1c0.5-6.6,0-22.4-5.6-35.2
	c-2.3-5.4-10.5-20.1-23.1-27.7c-3.5-2.1-21.3-8.3-38.1-5.3C292.2,182.8,276.1,195.8,276.1,195.8z"/>
<path fill="#AC9C2F" d="M192.9,152.8l13.6-2.8c0,0,0,2.2,0,3.4c0,8.9-0.1,17.7,0,26.6c0.1,4.8,0.7,9.6,0.8,14.4
	c0.1,5.5,0.2,10.9,0,16.4c-0.2,6.5-0.2,13-0.9,19.5c-0.8,7.4-3.1,14.5-5.9,21.5c-2.4,6.1-6.9,14.1-9.4,19.9l-3.6-12
	c1.9-5,3-10.3,3.9-15.7c1.7-10.5,1.3-21,1.6-31.5c0.1-5.4,0.2-10.8,0.9-16.2c0.9-5.8,0.2-11.6,0.3-17.4
	C194.2,171.7,192.9,152.8,192.9,152.8z"/>
<path fill="#AC9C2F" d="M262.1,136.3l-4.7-4.8c0,0,5.1-2.1,11.1-3.5s12.8-2.1,15.2-2.3c2.3-0.1,9.6-0.8,16.2,1.5
	c1.6,0.6,10,3.2,13.2,8.7c0.8,1.4,2.3,9.3-1,14.7c-3.2,5.3-11,8-14.4,8.6c-7.6,1.2-16.4-0.5-19.6-1.5c-3-0.9-13.6-6.2-22.4-12.8
	c-8.1-6-14.5-13.4-14.9-13.7c2.6-2.9,4.9-5.5,7.4-8.4c0.2,0.2,5.8,7.9,11.9,14.2c2.9,3,8.6,7.4,14.6,10.4c3.6,1.8,9,4.1,14.8,4.5
	c1.1,0.1,10.8,0.7,14.6-2.8c2-1.8,2.9-8.8-1.7-12.7c-4.2-3.6-13.7-4.2-18.2-4C274.1,133.2,262.1,136.3,262.1,136.3z"/>
<path fill="#AC9C2F" d="M211.1,147.2l-19,4.2c-5.8,0.9-2.1,0.6-7.9,1.2c-4.6,0.5-9.2,0.4-13.8-1.4c-3.5-1.3-6.3-3.2-8.8-6.1
	c-3.9-4.5-3.6-10.8,0.9-14.8c4-3.5,8.6-5,13.6-5.4c3.8-0.3,9.8-0.3,13.6-0.2l0.1,7c0,0,0,0,0,0c-1.9-0.4-9.4-0.9-14.1,1
	c-2.6,1-7.7,3.4-5.2,7.9c1,1.9,4.9,4.3,9.8,5.1c2.8,0.5,5.4,1.3,11,0.9l14.5-2.6L211.1,147.2z"/>
<polygon fill="#AC9C2F" points="208.2,162.1 218.7,153.7 223.6,158.6 208.4,175.2 "/>
<polygon fill="#AC9C2F" points="248,141.1 238.6,146.5 232.7,143 243,136.6 "/>
<path fill="#AC9C2F" d="M207.6,70.5"/>
<text transform="matrix(1 0 0 1 305.2319 205.5882)" fill="#FFFFFF" font-family="'Optima-ExtraBlack'" font-size="60px">E</text>
<text transform="matrix(1 0 0 1 364.8115 205.5882)" fill="#FFFFFF" font-family="'Optima-ExtraBlack'" font-size="60px">L</text>
<text transform="matrix(1 0 0 1 422.1708 205.5882)" fill="#FFFFFF" font-family="'Optima-ExtraBlack'" font-size="60px">E</text>
<text transform="matrix(1 0 0 1 481.7504 205.5882)" fill="#FFFFFF" font-family="'Optima-ExtraBlack'" font-size="60px">N</text>
<text transform="matrix(1 0 0 1 552.4301 205.5882)" fill="#FFFFFF" font-family="'Optima-ExtraBlack'" font-size="60px">A</text>
<text transform="matrix(1 0 0 1 618.8306 205.2703)" fill="#FFFFFF" font-family="'OptimaLTStd'" font-size="60px">K</text>
<text transform="matrix(1 0 0 1 676.1909 205.2703)" fill="#FFFFFF" font-family="'OptimaLTStd'" font-size="60px">O</text>
<text transform="matrix(1 0 0 1 749.0903 205.2703)" fill="#FFFFFF" font-family="'OptimaLTStd'" font-size="60px">L</text>
<text transform="matrix(1 0 0 1 802.0103 205.2703)" fill="#FFFFFF" font-family="'OptimaLTStd'" font-size="60px">E</text>
<text transform="matrix(1 0 0 1 854.9302 205.2703)" fill="#FFFFFF" font-family="'OptimaLTStd'" font-size="60px">S</text>
<text transform="matrix(1 0 0 1 907.8501 205.2703)" fill="#FFFFFF" font-family="'OptimaLTStd'" font-size="60px">N</text>
<text transform="matrix(1 0 0 1 977.4507 205.2703)" fill="#FFFFFF" font-family="'OptimaLTStd'" font-size="60px">I</text>
<text transform="matrix(1 0 0 1 1017.0503 205.2703)" fill="#FFFFFF" font-family="'OptimaLTStd'" font-size="60px">T</text>
<text transform="matrix(1 0 0 1 1073.3306 205.2703)" fill="#FFFFFF" font-family="'OptimaLTStd'" font-size="60px">S</text>
<text transform="matrix(1 0 0 1 1126.2505 205.2703)" fill="#FFFFFF" font-family="'OptimaLTStd'" font-size="60px">C</text>
<text transform="matrix(1 0 0 1 1189.1899 205.2703)" fill="#FFFFFF" font-family="'OptimaLTStd'" font-size="60px">H</text>
<text transform="matrix(1 0 0 1 1258.7896 205.2703)" fill="#FFFFFF" font-family="'OptimaLTStd'" font-size="60px">E</text>
<text transform="matrix(1 0 0 1 1311.7095 205.2703)" fill="#FFFFFF" font-family="'OptimaLTStd'" font-size="60px">N</text>
<text transform="matrix(1 0 0 1 1381.3101 205.2703)" fill="#FFFFFF" font-family="'OptimaLTStd'" font-size="60px">K</text>
<text transform="matrix(1 0 0 1 1438.6704 205.2703)" fill="#FFFFFF" font-family="'OptimaLTStd'" font-size="60px">O</text>
</svg> -->


<img src="/assets/logo/Logo_neu3_farbe-min.png" class="bg" width="100%" height="100%">