import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { VitaComponent } from './pages/vita/vita.component';
import { MediaComponent } from './pages/media/media.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import {ConcertsansorchestreComponent} from './concertsansorchestre/concertsansorchestre.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  }, {
    path: 'vita',
    component: VitaComponent
  }, {
    path: 'media',
    component: MediaComponent
  }, {
    path: 'contact',
    component: ContactComponent
  }, {
    path: 'impressum',
    component: ImpressumComponent
  } , {
    path: 'concertsansorchestre',
    component: ConcertsansorchestreComponent
  } 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
