<br>
<div class="container" style="margin-top:30px">
    <div class="row">
      <div class="col-sm-4">
        <h1>Concert Sans Orchestre</h1>
      </div>
      <div class="col-sm-8 text-justify">        
        <p>„Concert sans orchestre“ - ein facettenreiches Album für Soloklavier, das mit keinem anderen Soloinstrument möglich wäre, denn das Klavier ist die komprimierte Form des Orchesters.
            <br>
            Das Programm präsentiert neben bekannten Werken von Bach, Mozart und Schumann, eher unbekannte Kompositionen von Saint-Saëns und Skrjabin, und stellt die orchestrale Qualität des Klaviers und seine nahezu unbegrenzten Möglichkeiten heraus.  
        </p>
        </div>        
    </div>
    <div class="row">
      <div class="col-sm-4">
      </div>
      <div class="col-sm-8 text-justify">        
        <p>
            Programm:
            <br>
            Saint-Saëns: Allegro d‘après le 3e Concerto, op. 29
            <br>
            Mozart: Klaviersonate B-Dur, KV 333
            <br>
            Skrjabin: Allegro de concert b-Moll, op. 18
            <br>
            Bach: Italienisches Konzert F-Dur, BWV 971
            <br>
            Schumann: Concert sans orchestre f-Moll, op. 14
            <br>
        </p>
        </div>        
    </div>
  
    <div class="row">
      <div class="col-sm-4">
      </div>
      <div class="col-sm-8 text-justify">        
        <p>
          In Zusammenarbeit mit:
          <br>
          <a target="_blank" rel="noopener noreferrer" href="https://www.bechstein.com/">C. Bechstein</a>
          <br>
          <a target="_blank" rel="noopener noreferrer" href="https://www.funk-stiftung.org/">Funk Stiftung</a>
          <br>
          <a target="_blank" rel="noopener noreferrer" href="https://www.b-sharp.de/">Tonstudio b-sharp</a>
          <br>
          <a target="_blank" rel="noopener noreferrer" href="https://gwk-online.de/musik/publikationen/">GWK Records</a>
          <br>
        </p>
        </div>        
    </div>
  </div>