<div class="container" style="margin-top:30px">
  <div class="row">
    <div class="col-sm-4">
      <h1>Aktuelles</h1>
    </div>
    <div class="col-sm-8">      
      
      </div>        
  </div>
</div>

<div class="container" style="margin-top:30px" *ngFor="let item of news">
  <div class="row">
    <div class="col-sm-4">
    </div>
    <div class="col-sm-8">  
      <img class="img-fluid image" *ngIf="item.image" mat-card-image [src]="item.image" [alt]="item.title">
      <div class="embed-responsive embed-responsive-16by9" *ngIf="item.youtubeurl">
      <iframe class="embed-responsive-item" [src]="item.safeurl" [title]="item.title" frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <br>
      <br>
      <h5>{{item.date}}  {{item.location}}</h5>        
      <h5>{{item.title}}</h5>
      <p [innerHTML]="item.description"></p>
      <a target='_blank' rel="noopener noreferrer" href="{{item.link}}">{{item.linktitle}}</a>
      <br>
      </div>        
  </div>
</div>