<br>
<div class="container" style="margin-top:30px">
    <div class="row">
      <div class="col-sm-4">
        <h1>Vita</h1>
      </div>
      <div class="col-sm-8 text-justify">        
        <p>Videos vom Schlussapplaus auf Facebook? Blumenstrauß-Fotos mit Konzertveranstaltern auf
          Instagram? Eine Kostprobe des neu einstudierten Rachmaninow-Stücks bei YouTube? Nimmt man all dies zum Maßstab, kann Elena
          Kolesnitschenko keine bedeutende Pianistin sein. Denn all das sucht man vergeblich.
        </p>
        </div>        
    </div>
    <div class="row">
      <div class="col-sm-4">
      </div>
      <div class="col-sm-8 text-justify">        
        <p>
          Spricht man allerdings mit Besuchern ihrer Konzerte – ob passionierter Klassik-Fan oder aus
          Neugier mitgekommener Nachbar, ob große Philharmonie in Köln oder kleines
          Gemeindehaus auf dem Land - sieht das völlig anders aus. Weil Elena Kolesnitschenko sich
          ganz auf das fokussiert, was sie kann: Klavier spielen. Ihre Stücke leben. Und mit einer
          Leidenschaft beleben, die niemanden unberührt lässt. Und bei der jeder spürt, dass das eine
          sehr erwachsene, eigenständige Künstlerin am Flügel sitzt, die nichts abspult, sondern alles
          gestaltet.
        </p>
        </div>        
    </div>
    <div class="row">
      <div class="col-sm-4">
      </div>
      <div class="col-sm-8 text-justify">        
        <p>
          Eine Haltung, die auch in ihrer Biografie begründet liegt. Ein Kinderstar, den Russland stolz
          herumzeigte, bis hin zu Konzerten vor dem Papst und bei den Vereinten Nationen. Gedrillt
          auf ständige Verbesserung, auf Konkurrenzdenken, auf Wettbewerbe.
        </p>
        </div>        
    </div>
    <div class="row">
      <div class="col-sm-4">
      </div>
      <div class="col-sm-8 text-justify">        
        <p>
          Aus dieser Zeit stammt das Rüstzeug, dass sie heute zu einer Pianistin von internationalem
          Niveau macht. Ihre Lehrer, ob am Konservatorium in Moskau oder später mit Prof. Krainev
          an der Hochschule für Musik und Theater in Hannover, gehörten zu den besten ihres Fachs.
          Kristallklarer Anschlag, rhythmische Vitalität und leidenschaftliche Interaktion mit dem
          Instrument kennzeichnen heute Elena Kolesnitschenkos Spiel.
        </p>
        </div>        
    </div>
    <div class="row">
      <div class="col-sm-4">
      </div>
      <div class="col-sm-8 text-justify">        
        <p>
          Auf dieser Zeit fußt aber auch die Wandlung zur eigenständigen Künstlerin. Der Abschied von
          dem Gedanken, Musik sei etwas, dessen Qualität sich in der Rangfolge von Wettbewerben
          bemessen lasse. Oder von dem Gedanken, auf dem umkämpften Musikmarkt müsse eben
          jeder die Ellenbogen ausfahren. Eine Entwicklung der Persönlichkeit von Elena
          Kolesnitschenko, die sich unter anderem durch die beiden Dokumentarfilme „Russlands
          Wunderkinder“ und „Die Konkurrenten“ von Irene Langemann nachvollziehen lässt.
        </p>
        </div>        
    </div>
    <div class="row">
      <div class="col-sm-4">
      </div>
      <div class="col-sm-8 text-justify">        
        <p>
          Und so spielt sie heute Klavier. Ernsthaft, eigenständig und voller Leidenschaft und Liebe zu
          Musik und Publikum zugleich. Wenn sie auftritt, gestaltet sie eine stimmige musikalische
          Geschichte. Konzerte von Elena Kolesnitschenko sind deshalb keine Events, keine
          Inszenierungen. Sie sind Musik pur, ohne Effekthascherei und große Geste. Ihre natürliche
          Ausstrahlung zieht den Zuhörer auch ganz persönlich in ihren Bann, ohne dass sie es darauf
          anlegt. Die fehlende Präsenz bei Facebook, Instagram und Co – sie ist kein Zufall, sie ist die
          Konsequenz aus der Art und Weise, wie Elena Kolesnitschenko Musik macht und wie sie als
          Mensch ist. So verschlossen die Pianistin mit Blick auf die sozialen Netzwerke scheint – so
          offen und herzlich ist sie im persönlichen Gespräch oder in der Gruppe.
        </p>
        </div>        
    </div>
    <div class="row">
      <div class="col-sm-4">
      </div>
      <div class="col-sm-8 text-justify">        
        <p>
          Eine Begegnung mit dieser Künstlerin und ihrem Spiel kann unsere Haltung zur Musik
          verändern. Und eine Liebe und ein Verständnis wecken, die in uns verborgen lagen, ohne
          dass wir es ahnten.
        </p>
        </div>        
    </div>
  </div>