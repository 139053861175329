<logo></logo>

<nav class="navbar  navbar-expand-lg top-navbar navbar-custom">
  <div class="container">      
    <button class="navbar-toggler" (click)="showNav = !showNav" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
  </button>
      <div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
          <ul class="navbar-nav" [ngClass]="{'show': showNav}">            
            <li class="nav-item" [routerLink]="['/']" (click)="showNav = false">Home</li>
            <li class="nav-item" [routerLink]="['vita']" (click)="showNav = false">Vita</li>
            <li class="nav-link" [routerLink]="['media']" (click)="showNav = false">Media</li>
            <li class="nav-link" [routerLink]="['contact']" (click)="showNav = false">Kontakt</li>
          </ul>
      </div>
  </div>
</nav>
