import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,  ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { MaterialComponentsModule } from './core/material-components.module';
import { ServicesService } from './contact.service.service';
// Pages
import { HomeComponent } from './pages/home/home.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { ContactComponent } from './pages/contact/contact.component';
import { MediaComponent } from './pages/media/media.component';
import { VitaComponent } from './pages/vita/vita.component';
// Components
import { LogoComponent } from './components/logo/logo.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ImageLightboxComponent } from './components/image-lightbox/image-lightbox.component';
import { ConcertsansorchestreComponent } from './concertsansorchestre/concertsansorchestre.component';
import { ContactformComponent } from './components/contactform/contactform.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ImpressumComponent,
    ContactComponent,
    LogoComponent,
    MediaComponent,
    NavbarComponent,
    VitaComponent,
    ImageLightboxComponent,
    ConcertsansorchestreComponent,
    ContactformComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    MaterialComponentsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    ServicesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
