<br>
<div class="container" style="margin-top:30px">
    <div class="row">
      <div class="col-sm-4">
        <h1>Galerie</h1>
      </div>
      <div class="col-sm-8">        
        <section class="gallery-block grid-gallery">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-lg-4 item">
                        <a class="lightbox" href="./assets/img/elena_neu2.jpg">
                            <img class="img-fluid image scale-on-hover" src="./assets/img/elena_neu2.jpg">
                        </a>
                    </div>
                    <div class="col-md-6 col-lg-4 item">
                        <a class="lightbox" href="./assets/img/elena_neu1.jpg">
                            <img class="img-fluid image scale-on-hover" src="./assets/img/elena_neu1.jpg">
                        </a>
                    </div>
                    <div class="col-md-6 col-lg-4 item">
                        <a class="lightbox" href="./assets/img/elena_neu3.jpg">
                            <img class="img-fluid image scale-on-hover" src="./assets/img/elena_neu3.jpg">
                        </a>
                    </div>
                    <div class="col-md-6 col-lg-4 item">
                        <a class="lightbox" href="./assets/img/elanapiano.jpg">
                            <img class="img-fluid image scale-on-hover" src="./assets/img/elanapiano.jpg">
                        </a>
                    </div>
                    <div class="col-md-6 col-lg-4 item">
                        <a class="lightbox" href="./assets/img/elena_neu5.jpg">
                            <img class="img-fluid image scale-on-hover" src="./assets/img/elena_neu5.jpg">
                        </a>
                    </div>
                    <div class="col-md-6 col-lg-4 item">
                        <a class="lightbox" href="./assets/img/elena_5.jpg">
                            <img class="img-fluid image scale-on-hover" src="./assets/img/elena_5.jpg">
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </div>
  </div>

  <div class="container" style="margin-top:30px">
    <div class="row">
      <div class="col-sm-4">
        <h2>Diskographie</h2>
      </div>
      <div class="col-sm-8">     
        <section class="gallery-block grid-gallery">
        <div class="container">
          <div class="row">
              <div class="col-md-8 col-lg-4 item">
                 <img class="img-fluid image" src="./assets/mediacover/cd1.png">                 
              </div>
              <div class="col-md-4 col-lg-8 item">
                  <h5>
                    Beethoven / Thalberg
                  </h5>
                  <p>
                    Sonata No. 7 in D-Major op. 10/3
                    <br>
                    Sonata No. 9 in E-Major op. 14/1
                    <br>
                    Sonata No. 10 in G-Major op. 14/2
                    <br>
                    Souvernirs de Beethoven: Grande Fantaisie pour le piano
                    sur la 7e Symphonie de Beethoven
                  </p>
              </div>
          </div>
        </div>
      </section>
      </div>

      <div class="row">
        <div class="col-sm-4">         
        </div>
      <div class="col-sm-8">     
        <section class="gallery-block grid-gallery">
        <div class="container">         
          <div class="row">
            <div class="col-md-8 col-lg-4 item">
               <img class="img-fluid image" src="./assets/mediacover/Cover-Kunst-der-Variation.jpg">                 
            </div>
            <div class="col-md-4 col-lg-8 item">
                <h5 id=anker1>
                  Die Kunst der Variation
                </h5>
                <p>
                 Sergei Rachmaninov: Variationen über ein Thema von Corelli op. 42
                 <br>
                 Felix Mendelsohn-Bartoldy: Variations serieuses op. 54
                 <br>
                 Robert Schumann: Sinfonische Etuden op. 13 (Fassung von E. Kolesnitschenko)
                </p>
            </div>
        </div>
        </div>
      </section>
      </div>

      <div class="row">
        <div class="col-sm-4">         
        </div>
      <div class="col-sm-8">     
        <section class="gallery-block grid-gallery">
        <div class="container">         
          <div class="row">
            <div class="col-md-8 col-lg-4 item">
               <img class="img-fluid image" src="./assets/mediacover/Concert_Sans_Orchestre_Cover.png">                 
            </div>
            <div class="col-md-4 col-lg-8 item">
                <h5 id=anker1>
                  Concert Sans Orchestre
                </h5>
                <p>
                  Saint-Saëns: Allegro d‘après le 3e Concerto, op. 29
                  <br>
                  Mozart: Klaviersonate B-Dur, KV 333
                  <br>
                  Skrjabin: Allegro de concert b-Moll, op. 18
                  <br>
                  Bach: Italienisches Konzert F-Dur, BWV 971
                  <br>
                  Schumann: Concert sans orchestre f-Moll, op. 14
                  <br>
                </p>
            </div>
        </div>
        </div>
      </section>
      </div>
    </div>
</div>

  <div class="container" style="margin-top:30px">
    <div class="row">
      <div class="col-sm-4">
        <h2>Filme</h2>
      </div>
      <div class="col-sm-8">     
        <section class="gallery-block grid-gallery">
        <div class="container">
          <div class="row">
              <div class="col-md-8 col-lg-4 item">
                 <img class="img-fluid image" src="./assets/mediacover/film1.jpg">                 
              </div>
              <div class="col-md-4 col-lg-4 item">
                  <h5>
                   Russlands Wunderkinder
                  </h5>
                  <p>
                    Ein Dokumentarfilm von Irene Langemann
                    <br>
                    <br>
                    D 2000 / OmU / 98 min / Lichtfilm
                  </p>
                  <a target="_blank" rel="noopener noreferrer" href="http://www.lichtfilm.de/de/filme/filmkatalog/373-russlands-wunderkinder-1">weitere Informationen</a>
              </div>
          </div>
        </div>
      </section>
    </div>
</div>

<div class="container" style="margin-top:30px">
    <div class="row">
      <div class="col-sm-4">
      </div>
      <div class="col-sm-8">     
        <section class="gallery-block grid-gallery">
        <div class="container">
          <div class="row">
              <div class="col-md-8 col-lg-4 item">
                 <img class="img-fluid image" src="./assets/mediacover/film2.jpg">                 
              </div>
              <div class="col-md-4 col-lg-4 item">
                  <h5>
                    Die Konkurrenten - Russlands Wunderkinder II
                  </h5>
                  <p>
                    Ein Dokumentarfilm von Irene Langemann
                    <br>
                    <br>
                    D 2010 / OmU / 98 min / Lichtfilm
                  </p>
                  <a target="_blank" rel="noopener noreferrer" href="http://www.lichtfilm.de/de/filme/filmkatalog/343-die-konkurrenten-russlands-wunderkinder">weitere Informationen</a>
              </div>
          </div>
        </div>
      </section>
    </div>
</div>

<div class="container" style="margin-top:30px">
  <div class="row">
    <div class="col-sm-4">
      <h2>Interviews und Berichte</h2>
    </div>
    <div class="col-sm-8">     
      <section class="gallery-block grid-gallery">
      <div class="container">
        <div class="row">
            <div class="col-md-8 col-lg-4 item">
               <!-- <img class="img-fluid image" src="./assets/mediacover/film1.jpg">                  -->
            </div>
            <div class="col-md-4 col-lg-4 item">
                <h5>
                 Piano News
                </h5>
                <p>
                  Im Gespräch mit Carsten Dürer von Piano News im Steinway Haus in Düsseldorf.
                  <br>
                  03 Dezember 2020
                </p>
                <a target="_blank" rel="noopener noreferrer" href="https://www.pianonews.de/index.php/service/video-interviews/63-pianonews-interviews/414-pianonews-video-interviews-13">Zum Interview</a>
        </div>
        </div>
      </div>
    </section>
  </div>
</div>

<div class="container" style="margin-top:30px">
  <div class="row">
    <div class="col-sm-4">
      <h2></h2>
    </div>
    <div class="col-sm-8">     
      <section class="gallery-block grid-gallery">
      <div class="container">
        <div class="row">
            <div class="col-md-8 col-lg-4 item">
               <!-- <img class="img-fluid image" src="./assets/mediacover/film1.jpg">                  -->
            </div>
            <div class="col-md-4 col-lg-4 item">
                <h5>
                 Deutschlandfunk Kultur
                </h5>
                <p>
                  Beitrag beim Deutschlandfunk Kultur mit Klaus Schirmer und Caro Korneli.
                  <br>
                  24 April 2020
                </p>
                <a target="_blank" rel="noopener noreferrer" href="https://www.deutschlandfunkkultur.de/status-wunderkind-wenn-frueher-erfolg-zur-last-wird.4004.de.html?dram:article_id=475300">Zur Sendung</a>
            </div>
        </div>
      </div>
    </section>
  </div>
</div>


<!-- <h2>Galerie</h2>

<div class="gallery" fxLayout="row wrap" fxLayoutAlign="center">
  <image-lightbox *ngFor="let image of images" [image]="image.url" [fxFlex.gt-sm]="itemSize" [fxFlex.lt-md]="itemSize*2"></image-lightbox>
</div>


<h2>Diskographie</h2>

<div fxLayout="row wrap" fxLayoutAlign="center">
  <div class="cards-content" *ngFor="let cd of cds" fxFlex="50">
    <mat-card>
      <img mat-card-image [src]="cd.image" [alt]="cd.title">
      <mat-card-header>
        <mat-card-title>{{cd.title}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p [innerHTML]="cd.description"></p>
      </mat-card-content>
    </mat-card>
  </div>
</div>


<h2>Filme</h2>

<div fxLayout="row wrap" fxLayoutAlign="center">
  <div class="cards-content" *ngFor="let movie of movies" fxFlex="50">
    <mat-card>
      <img mat-card-image [src]="movie.image" [alt]="movie.title">
      <mat-card-header>
        <mat-card-title>{{movie.title}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p [innerHTML]="movie.description"></p>
      </mat-card-content>
      <mat-card-actions>
        <a *ngIf="movie.link" [href]="movie.link" mat-button>Weitere Informationen</a>
      </mat-card-actions>
    </mat-card>
  </div>
</div> -->