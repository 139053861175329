import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm } from '@angular/forms'
import { ServicesService } from 'src/app/contact.service.service';
import { HttpClient } from '@angular/common/http';

export interface Response {
  type: string;
  text: string;
}

@Component({
  selector: 'app-contactform',
  templateUrl: './contactform.component.html',
  styleUrls: ['./contactform.component.scss']
})

export class ContactformComponent implements OnInit {
  FormData: FormGroup;
  
  constructor(private builder: FormBuilder, private contact: ServicesService, private http: HttpClient) { }

  ngOnInit() {
    this.FormData = this.builder.group({
      contactName: new FormControl('', [Validators.required]),
      contactEmail: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
      contactMessage: new FormControl('', [Validators.required])
    });
  }

  // Function to send data to Backend
  send(FormData): void {
    // Function to send data to Backend
    const contactName = 'Max Mustermann';
    const contactEmail = 'patrick.kirchmann@gmail.com';
    const contactMessage = 'Lorem ipsum';
    const post_data = {
      'form': 'contactForm',
      'contactSpamChecking': '',
      'contactName': contactName,
      'contactEmail': contactEmail,
      'contactMessage': contactMessage
    };

    this.http.post<Response>('http://localhost/mail.php', post_data).subscribe((response: Response) => {
      if (response) {
        alert(response.text);
      }
    });
  }


/*   onSubmit(FormData) {
    console.log(FormData)
    this.contact.PostMessage(FormData)
      .subscribe(response => {
        location.href = 'https://formspree.io/f/xqkwbvdq'
        console.log(response)
      }, error => {
        console.warn(error.responseText)
        console.log({ error })
      })
  } */
}
