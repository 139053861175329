import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {

  public images = [
    {
      url: "./assets/img/elena_5.jpg"
    }, {
      url: "./assets/img/elena_6.jpg"
    }, {
      url: "./assets/img/elanapiano2.jpg"
    }, {
      url: "./assets/img/elena_2.jpg"
    }, {
      url: "./assets/img/elena_4.jpg"
    }, {
      url: "./assets/img/elena_8.jpg"
    }
  ];
  public cds = [
    {
      image: "./assets/mediacover/cd1.png",
      title: "Beethoven / Thalberg",
      description: "Sonata No. 7 in D-Major op. 10/3<br>Sonata No. 9 in E-Major op. 14/1<br>Sonata No. 10 in G-Major op. 14/2<br>Souvernirs de Beethoven: Grande Fantaisie pour le pianosur la 7e Symphonie de Beethoven",
      link: ""
    }, {
      image: "./assets/mediacover/Cover-Kunst-der-Variation.jpg",
      title: "Die Kunst der Variation",
      description: "Sergei Rachmaninov<br>\"Variationen über ein Thema von Corelli\" op. 42<br>Felix Mendelsohn-Bartoldy<br>\"Variations serieuses\" op. 54<br>Robert Schumann<br>\"Sinfonische Etuden\" op. 13 (Fassung von E. Kolesnitschenko)",
      link: ""
    }
  ];
  public itemSize = 100;
  public movies = [
    {
      image: "./assets/mediacover/film1.jpg",
      title: "Russlands Wunderkinder",
      description: "Ein Dokumentarfilm von Irene Langemann<br>D 2000 / OmU / 98 min / Lichtfilm",
      link: "http://www.lichtfilm.de/de/filme/filmkatalog/373-russlands-wunderkinder-1"
    }, {
      image: "./assets/mediacover/film2.jpg",
      title: "Die Konkurrenten - Russlands Wunderkinder II",
      description: "Ein Dokumentarfilm von Irene Langemann<br>D 2010 / OmU / 98 min / Lichtfilm",
      link: "http://www.lichtfilm.de/de/filme/filmkatalog/343-die-konkurrenten-russlands-wunderkinder"
    }
  ];

  constructor() { }

  ngOnInit() {
    if (this.images.length <= 6) {
      this.itemSize = 100 / this.images.length;
    }
  }

}
