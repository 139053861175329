import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {

  public showNav = false;

  constructor() { }

  ngOnInit() {
  }

}
